import React from 'react';
import { Toast } from 'flowbite-react';
import { FaTelegramPlane, FaSkull, FaSmileWink } from 'react-icons/fa';

// Create an object to map icon names to actual components
const iconMap = {
    FaTelegramPlane,
    FaSkull,
    FaSmileWink,
    // add more mappings here as needed
};

export default function ToastNotification({ title, icon }) {
    // Look up the icon component based on the passed in `icon` string
    const IconComponent = iconMap[icon];

    return (
        <div className="fixed top-4 right-4 space-x-4 divide-x divide-gray-200 dark:divide-gray-700">
            <Toast>
                {/* Conditionally render the icon if it exists */}
                {IconComponent && <IconComponent className="h-5 w-5 text-cyan-600 dark:text-cyan-500" />}
                <div className="pl-4 text-sm font-normal">
                    {title}
                </div>
            </Toast>
        </div>
    );
}
