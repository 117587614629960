export default function ContactGrid() {
    return (
        <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl space-y-16 divide-y divide-gray-100 lg:mx-0 lg:max-w-none">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
                        <div>
                            <h2 className="text-3xl font-bold tracking-tight text-gray-900">Begin Your Wellness Conversation</h2>
                            <p className="mt-4 leading-7 text-gray-600">
                                Step into a world where health and care converge.
                            </p>
                        </div>
                        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">
                            <div className="rounded-2xl bg-gray-50 p-10">
                                <h3 className="text-base font-semibold leading-7 text-gray-900">Appointments</h3>
                                <dl className="mt-3 space-y-1 text-sm leading-6 text-gray-600">
                                    <div>
                                        <dt className="sr-only">Email</dt>
                                        <dd>
                                            <a className="font-semibold text-indigo-600" href="mailto:appointments@anita.ng">
                                                appointments@anita.ng
                                            </a>
                                        </dd>
                                    </div>
                                    <div className="mt-1">
                                        <dt className="sr-only">Phone number</dt>
                                        <dd>+(234) 813 529 1551</dd>
                                    </div>
                                </dl>
                            </div>
                            <div className="rounded-2xl bg-gray-50 p-10">
                                <h3 className="text-base font-semibold leading-7 text-gray-900">Inquiries</h3>
                                <dl className="mt-3 space-y-1 text-sm leading-6 text-gray-600">
                                    <div>
                                        <dt className="sr-only">Email</dt>
                                        <dd>
                                            <a className="font-semibold text-indigo-600" href="mailto:inquiries@anita.ng">
                                                inquiries@anita.ng
                                            </a>
                                        </dd>
                                    </div>
                                    <div className="mt-1">
                                        <dt className="sr-only">Phone number</dt>
                                        <dd>+(234) 813 529 1551</dd>
                                    </div>
                                </dl>
                            </div>
                            <div className="rounded-2xl bg-gray-50 p-10">
                                <h3 className="text-base font-semibold leading-7 text-gray-900">Partnerships & Events</h3>
                                <dl className="mt-3 space-y-1 text-sm leading-6 text-gray-600">
                                    <div>
                                        <dt className="sr-only">Email</dt>
                                        <dd>
                                            <a className="font-semibold text-indigo-600" href="mailto:partnerships@anita.ng">
                                                partnerships@anita.ng
                                            </a>
                                        </dd>
                                    </div>
                                    <div className="mt-1">
                                        <dt className="sr-only">Phone number</dt>
                                        <dd>+(234) 813 529 1551</dd>
                                    </div>
                                </dl>
                            </div>
                            <div className="rounded-2xl bg-gray-50 p-10">
                                <h3 className="text-base font-semibold leading-7 text-gray-900">Feedback & Testimonials</h3>
                                <dl className="mt-3 space-y-1 text-sm leading-6 text-gray-600">
                                    <div>
                                        <dt className="sr-only">Email</dt>
                                        <dd>
                                            <a className="font-semibold text-indigo-600" href="mailto:feedback@anita.ng">
                                                feedback@anita.ng
                                            </a>
                                        </dd>
                                    </div>
                                    <div className="mt-1">
                                        <dt className="sr-only">Phone number</dt>
                                        <dd>+(234) 813 529 1551</dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 pt-16 lg:grid-cols-3">
                        <div>
                            <h2 className="text-3xl font-bold tracking-tight text-gray-900">Doorstep Therapy</h2>
                            <p className="mt-4 leading-7 text-gray-600">
                                When it's challenging to step out, we step in. Delivering top-notch care right to your door.
                            </p>
                        </div>
                        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">
                            <div className="rounded-2xl bg-gray-50 p-10">
                                <h3 className="text-base font-semibold leading-7 text-gray-900">Lagos, Nigeria</h3>
                                <address className="mt-3 space-y-1 text-sm not-italic leading-6 text-gray-600">
                                    <p>Lekki, Lagos</p>
                                    {/* <p>Los Angeles, CA 90210</p> */}
                                </address>
                            </div>
                            {/* <div className="rounded-2xl bg-gray-50 p-10">
                                <h3 className="text-base font-semibold leading-7 text-gray-900">New York</h3>
                                <address className="mt-3 space-y-1 text-sm not-italic leading-6 text-gray-600">
                                    <p>886 Walter Street</p>
                                    <p>New York, NY 12345</p>
                                </address>
                            </div>
                            <div className="rounded-2xl bg-gray-50 p-10">
                                <h3 className="text-base font-semibold leading-7 text-gray-900">Toronto</h3>
                                <address className="mt-3 space-y-1 text-sm not-italic leading-6 text-gray-600">
                                    <p>7363 Cynthia Pass</p>
                                    <p>Toronto, ON N3Y 4H8</p>
                                </address>
                            </div> */}
                            <div className="rounded-2xl bg-gray-50 p-10">
                                <h3 className="text-base font-semibold leading-7 text-gray-900">Rivers, Nigeria</h3>
                                <address className="mt-3 space-y-1 text-sm not-italic leading-6 text-gray-600">
                                    <p>Port Harcourt, Rivers</p>
                                    {/* <p>Chicago, IL 60601</p> */}
                                </address>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
