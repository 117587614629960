import { CursorArrowRaysIcon, ComputerDesktopIcon, FingerPrintIcon, Square3Stack3DIcon } from '@heroicons/react/24/outline'

const features = [
    {
        name: 'Personalized Care Approach',
        description:
            "Every individual's journey to wellness is unique. Our treatments are meticulously designed to cater to your specific needs, ensuring optimal recovery and well-being.",
        icon: FingerPrintIcon,
    },
    {
        name: 'Evidence-Based Methods',
        description:
            'Driven by the latest research in physiotherapy, we employ techniques that are backed by science, ensuring you receive the most effective care available.',
        icon: Square3Stack3DIcon,
    },
    {
        name: 'Digital Resources & Support',
        description:
            'Continue your recovery at home with our curated set of digital resources. From exercise videos to informative articles, we ensure your wellness journey is well-supported.',
        icon: ComputerDesktopIcon,
    },
    {
        name: 'Flexible Appointment Options',
        description:
            'Understanding the demands of modern life, we offer flexible appointment times, including early mornings, evenings, and weekends, making it easier for you to prioritize your health.',
        icon: CursorArrowRaysIcon,
    },
]

export default function Features() {
    return (
        <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:text-center">
                    <h2 className="text-base font-semibold leading-7 text-indigo-600">Holistic Healing</h2>
                    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        Comprehensive Care for Your Wellness Journey
                    </p>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                        Delve into a world where personal care meets professional expertise.
                        Our approach is tailored to your unique needs, ensuring a path to renewed strength, mobility, and overall well-being.
                    </p>
                </div>
                <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                        {features.map((feature) => (
                            <div key={feature.name} className="relative pl-16">
                                <dt className="text-base font-semibold leading-7 text-gray-900">
                                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                                        <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </div>
                                    {feature.name}
                                </dt>
                                <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}
