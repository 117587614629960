import FS1 from "../Components/FeatureSection1";
import Footer from "../Components/Footer";
import Navbar from "../Components/NavBar";
import { Helmet } from 'react-helmet';

export default function Services() {
    return (
        <>
            <Helmet>
                <title>Services - Anita's Physiotherapy</title>
                <meta name="description" content="Explore a wide range of physiotherapy services offered by Anita's Physiotherapy. From rehabilitation to sports injury treatments, we have it all." />
                <meta name="keywords" content="Rehabilitation, Sports injury treatments, Physical therapy services, Pain management, Post-surgery care, Musculoskeletal therapy, Anita's Physiotherapy Nigeria, Physiotherapy Lagos" />
            </Helmet>
            <Navbar />
            <FS1 />
            {/* <Header title="Services" subtitle="We offer a wide range of services to help you get back on your feet." /> */}
            <Footer />
        </>
    )
}
