import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'

export default function CS2() {
    return (
        <div className="bg-white px-6 py-32 lg:px-8">
            <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
                <p className="text-base font-semibold leading-7 text-indigo-600">Health Matters</p>
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Prioritize Your Wellbeing</h1>
                <p className="mt-6 text-xl leading-8">
                    Good health isn't just the absence of disease; it's a holistic state of physical, emotional, and mental well-being. It's the foundation upon which we build our lives, allowing us to live fully and pursue our passions without restraint.
                </p>
                <div className="mt-10 max-w-2xl">
                    <p>
                        Achieving optimal health requires awareness, dedication, and consistent efforts. From balanced nutrition to regular exercise, from quality sleep to stress management, every choice counts. Recognizing the interconnectedness of body and mind, and making informed decisions can lead to a lifetime of vitality.
                    </p>
                    <ul role="list" className="mt-8 max-w-xl space-y-8 text-gray-600">
                        <li className="flex gap-x-3">
                            <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                            <span>
                                <strong className="font-semibold text-gray-900">Balanced Diet.</strong> Proper nutrition is the cornerstone of health, fueling the body and shielding it from illness.
                            </span>
                        </li>
                        <li className="flex gap-x-3">
                            <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                            <span>
                                <strong className="font-semibold text-gray-900">Physical Activity.</strong> Consistent exercise enhances stamina, strength, and flexibility, boosting overall health.
                            </span>
                        </li>
                        <li className="flex gap-x-3">
                            <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                            <span>
                                <strong className="font-semibold text-gray-900">Mental Wellbeing.</strong> Emotional and mental health are essential components, enabling resilience and a positive outlook.
                            </span>
                        </li>
                    </ul>
                    <p className="mt-8">
                        Proactive health choices lead to longer, fulfilling lives. By understanding the importance of holistic health and actively pursuing it, you are investing in a brighter, more energetic, and happier future.
                    </p>
                    <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">The Journey to Optimal Health</h2>
                    <p className="mt-6">
                        Health is an ongoing journey, filled with ups and downs. With perseverance, knowledge, and the right resources, you can navigate any challenges that come your way, ensuring that your health remains a top priority throughout your life.
                    </p>
                    <figure className="mt-10 border-l border-indigo-600 pl-9">
                        <blockquote className="font-semibold text-gray-900">
                            <p>
                                “Health is a state of complete physical, mental, and social well-being and not merely the absence of disease or infirmity.” - World Health Organization
                            </p>
                        </blockquote>
                        <figcaption className="mt-6 flex gap-x-4">
                            <img
                                className="h-6 w-6 flex-none rounded-full bg-gray-50"
                                src="https://drive.google.com/uc?export=view&id=1wdohPXrjcrUkb30pnJia9TtX5zKRST1H&auto=format&fit=crop&w=1587&q=80"
                                alt=""
                            />
                            <div className="text-sm leading-6">
                                <strong className="font-semibold text-gray-900">World Health Organization</strong>
                            </div>
                        </figcaption>
                    </figure>
                    <p className="mt-10">
                        Embrace wellness. Nurture your body, mind, and spirit. As you embark on this journey, remember that each step you take towards better health is a step towards a better life.
                    </p>
                </div>
                <figure className="mt-16">
                    <img
                        className="aspect-video rounded-xl bg-gray-50 object-cover"
                        src="https://drive.google.com/uc?export=view&id=1w-EE1pf_1p9mcZHiyIlD_mHcwmzJXhcx&auto=format&fit=crop&w=1587&q=80"
                        alt="A serene scene of tranquility, illustrating peace and well-being."
                    />
                    <figcaption className="mt-4 flex gap-x-2 text-sm leading-6 text-gray-500">
                        <InformationCircleIcon className="mt-0.5 h-5 w-5 flex-none text-gray-300" aria-hidden="true" />
                        Taking a moment for yourself aids in mental rejuvenation.
                    </figcaption>
                </figure>
                <div className="mt-16 max-w-2xl">
                    <h2 className="text-2xl font-bold tracking-tight text-gray-900">Holistic Health: Beyond the Physical</h2>
                    <p className="mt-6">
                        While physical health is tangible and often emphasized, our emotional, mental, and spiritual well-being play a crucial role in our overall health. By nurturing all aspects of self, we ensure a balanced, harmonious life.
                    </p>
                    <p className="mt-8">
                        Surround yourself with positive energy, seek support when needed, and trust in the power of holistic well-being. Health is wealth, and by cherishing it, you embrace a life of abundance.
                    </p>
                </div>
            </div>
        </div>
    )
}
