import React, { useState } from 'react';
import { CheckCircleIcon } from '@heroicons/react/20/solid'

export default function Alert({ title, subtitle }) {
    const [isVisible, setIsVisible] = useState(true);

    if (!isVisible) {
        return null; // Don't render the component if isVisible is false
    }

    return (
        <div className="rounded-md bg-green-50 p-4 transition-transform transform hover:-translate-y-1 hover:scale-105">
            <div className="flex">
                <div className="flex-shrink-0">
                    <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                    <h3 className="text-sm font-medium text-green-800">{title}</h3>
                    <div className="mt-2 text-sm text-green-700">
                        <p>{subtitle}</p>
                    </div>
                    <div className="mt-4">
                        <div className="-mx-2 -my-1.5 flex">
                            {/* <button
                                type="button"
                                className="rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                            >
                                View status
                            </button> */}
                            <button
                                type="button"
                                className="ml-3 rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                                onClick={() => setIsVisible(false)} // Set isVisible to false when clicked
                            >
                                Dismiss
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}