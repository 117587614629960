import BirthdayDropDown from "../Components/DropDownBirthday";
import Footer from "../Components/Footer";
import Navbar from "../Components/NavBar";
import PH1 from "../Components/PageHeader1";
import SH1 from "../Components/SectionHeading1";
import TB1 from "../Components/Textbox1";
import WishCard from "../Components/WishCard";

export default function Birthday() {
    const VideoComponent = ({ link }) => {
        return (
            <div className="mx-auto">
                <video className="mx-auto" width="320" height="240" controls controlsList="nodownload">
                    <source src={link} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        );
    }



    //     return (
    //         <>
    //             <Navbar />
    //             <PH1 title="Happy Birthday"
    //                 subtitle="This is a surprise for the celebrant. Please do not let her know. Write out a wish for her and it will be displayed here on her birthday when this would be unveiled to her. 🥳"
    //             />
    //             <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
    //                 <div className="mx-auto max-w-3xl"><TB1 /></div>
    //             </div>
    //             <br />
    //             <br />
    //             <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
    //                 <WishCard />
    //             </div>
    //             <Footer />
    //         </>
    //     )
    // }


    return (
        <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <Navbar />
                <PH1 title="Happy Birthday 🥳"
                    subtitle="Happy Birthday Messages From Everyone Far and Near On Your Own Special Day. 🥳"
                />
                <SH1 title="Dancing Wishes" />
                <br />
                <VideoComponent link={"https://drive.google.com/uc?export=view&id=1CBvMSr9hQm-DccshI2QQk51_53dHmBe-"} />
                <br />
                <VideoComponent link={"https://drive.google.com/uc?export=view&id=1hDnfZDzqaPK-16-Pa5FP4PN4vmlCN2Mq"} />
                <br />
                <SH1 title="Send a wish" subtitle="You could still send a wish below" />
                <br />
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="mx-auto max-w-3xl"><TB1 /></div>
                </div>
                <br />
                <SH1 title="Digital Party Pack" subtitle={"Thanks for coming to come celebrate with us"} />
                <br />
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="mx-auto max-w-3xl">
                        <BirthdayDropDown />
                    </div>
                </div>
                <br />
                <SH1 title="Birthday Wishes From Everyone 🥳" subtitle="And here are the birthday wishes from everyone" />
                <br />
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <WishCard />
                </div>
                <Footer />
            </div>
        </>
    )
}