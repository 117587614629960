import CS2 from "../Components/ContentSection2";
import Footer from "../Components/Footer";
import Navbar from "../Components/NavBar";

export default function Health() {
    return (
        <>
            <Navbar />
            <CS2 />
            <br />
            <Footer />
        </>
    )
}