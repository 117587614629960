import React, { useState, useEffect } from 'react';
import axios from 'axios';

export default function WishCard() {
    const [people, setPeople] = useState([]);
    const [selectedWish, setSelectedWish] = useState(null);

    useEffect(() => {
        axios.get('https://api.dinesurf.tech/anita/user_recent_wishes/')
            .then(response => {
                setPeople(response.data);
            })
            .catch(error => {
                console.error("There was an error fetching the wishes", error);
            });
    }, []);

    return (
        <div>
            <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                {people.map((person, index) => (
                    <li
                        key={index}
                        className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow cursor-pointer"
                        onClick={() => setSelectedWish(person)} // Set the clicked wish as selected
                    >
                        <div className="flex w-full items-center justify-between space-x-6 p-6">
                            <div className="flex-1 truncate">
                                <div className="flex items-center space-x-3">
                                    <h3 className="truncate text-sm font-medium text-gray-900">From: {person.username}</h3>
                                    <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                        Birthday Wish 🥳
                                    </span>
                                </div>
                                <p className="mt-1 truncate text-sm text-gray-500">{person.birthday_wish}</p>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>

            {/* Show LargeWish if a wish is selected */}
            {selectedWish && (
                <LargeWish
                    title={`FROM: ${selectedWish.username}`}
                    subtitle={selectedWish.birthday_wish}
                    onDismiss={() => setSelectedWish(null)} // Reset the selected wish to null
                />
            )}
        </div>
    );
}

function LargeWish({ title, subtitle, onDismiss }) {
    const handleBackdropClick = () => {
        onDismiss();
    };

    const handleContentClick = (e) => {
        e.stopPropagation();
    };

    return (
        <div
            className="fixed inset-0 flex items-center justify-center z-50"
            onClick={handleBackdropClick}
        >
            <div
                className="relative bg-slate-100 shadow sm:rounded-lg w-3/4 h-3/4 max-w-3xl overflow-hidden"
                onClick={handleContentClick}
            > {/* Make it occupy 75% of the viewport */}
                <div className="px-4 py-5 sm:p-6 overflow-auto">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">{title}</h3>
                    <h3 className="text-base font-semibold leading-6 text-gray-900">SUBJECT: BIRTHDAY WISH</h3>
                    <div className="mt-2 max-w-xl text-sm text-gray-500 flex items-center justify-center">
                        <p className="text-center">
                            {subtitle}
                        </p>
                    </div>
                </div>
                <button
                    type="button"
                    className="absolute bottom-5 right-5 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                    onClick={onDismiss}
                >
                    Dismiss
                </button>
            </div>
        </div>
    )
}
