export default function Badge({ title, href, onClick }) {
    return (
        <a
            href={href}
            onClick={onClick}
            className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"
            target="_blank"
            rel="noopener noreferrer"
        >
            {title}
        </a>
    )
}