import { PuzzlePieceIcon, UsersIcon, TrophyIcon, BookOpenIcon } from '@heroicons/react/20/solid'

export default function CS1() {
    return (
        <div className="relative isolate overflow-hidden bg-white py-24 sm:py-32">
            <div
                className="absolute -top-80 left-[max(6rem,33%)] -z-10 transform-gpu blur-3xl sm:left-1/2 md:top-20 lg:ml-20 xl:top-3 xl:ml-56"
                aria-hidden="true"
            >
                <div
                    className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
                    style={{
                        clipPath:
                            'polygon(63.1% 29.6%, 100% 17.2%, 76.7% 3.1%, 48.4% 0.1%, 44.6% 4.8%, 54.5% 25.4%, 59.8% 49.1%, 55.3% 57.9%, 44.5% 57.3%, 27.8% 48%, 35.1% 81.6%, 0% 97.8%, 39.3% 100%, 35.3% 81.5%, 97.2% 52.8%, 63.1% 29.6%)',
                    }}
                />
            </div>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0">
                    <p className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">Holistic Healing</p>
                    <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">The Power of Physiotherapy</h1>
                    <p className="mt-6 text-xl leading-8 text-gray-700">
                        Discover the transformative nature of physiotherapy.
                        Dive deep into a world where healing meets science, enhancing both the body's form and function.
                    </p>
                </div>
                <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:mt-10 lg:max-w-none lg:grid-cols-12">
                    <div className="relative lg:order-last lg:col-span-5">
                        <svg
                            className="absolute -top-[40rem] left-1 -z-10 h-[64rem] w-[175.5rem] -translate-x-1/2 stroke-gray-900/10 [mask-image:radial-gradient(64rem_64rem_at_111.5rem_0%,white,transparent)]"
                            aria-hidden="true"
                        >
                            <defs>
                                <pattern
                                    id="e87443c8-56e4-4c20-9111-55b82fa704e3"
                                    width={200}
                                    height={200}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <path d="M0.5 0V200M200 0.5L0 0.499983" />
                                </pattern>
                            </defs>
                            <rect width="100%" height="100%" strokeWidth={0} fill="url(#e87443c8-56e4-4c20-9111-55b82fa704e3)" />
                        </svg>
                        <figure className="border-l border-indigo-600 pl-8">
                            <blockquote className="text-xl font-semibold leading-8 tracking-tight text-gray-900">
                                <p>
                                    “Let's not wait for perfect conditions to begin.
                                    Let's begin to make perfection conditions for a healthy physical condition.”
                                </p>
                            </blockquote>
                            <figcaption className="mt-8 flex gap-x-4">
                                <img
                                    src="https://drive.google.com/uc?export=view&id=1EC43cszWUp168I9Tpx4x7Ngy3DEnluYf&auto=format&fit=crop&w=1587&q=80"
                                    alt=""
                                    className="mt-1 h-10 w-10 flex-none rounded-full bg-gray-50"
                                />
                                <div className="text-sm leading-6">
                                    <div className="font-semibold text-gray-900">Joerg Teichmann</div>
                                    <div className="text-gray-600">@joerg.teichmann2020</div>
                                </div>
                            </figcaption>
                        </figure>
                    </div>
                    <div className="max-w-xl text-base leading-7 text-gray-700 lg:col-span-7">
                        <p>
                            Physiotherapy is the art and science of promoting optimal health by using techniques to prevent, diagnose, and treat injuries and chronic conditions.
                            Through physical methods like massage, heat treatment, and exercise, it plays a vital role in promoting mobility and strength.
                        </p>
                        <ul role="list" className="mt-8 max-w-xl space-y-8 text-gray-600">
                            <li className="flex gap-x-3">
                                <PuzzlePieceIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                <span>
                                    <strong className="font-semibold text-gray-900">Personalized Care.</strong> Every individual brings their own set of unique experiences, challenges, and physiological intricacies.
                                    Recognizing this profound diversity, we delve deep into understanding the nuances of your specific condition and personal goals.
                                    Our therapy sessions aren't just generic routines; they are meticulously crafted, ensuring that each session aligns perfectly with your needs and accelerates your journey towards optimal well-being.
                                    Your healing and comfort are our utmost priority, and our commitment is to provide care that is as unique as you are.
                                </span>
                            </li>
                            <li className="flex gap-x-3">
                                <UsersIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                <span>
                                    <strong className="font-semibold text-gray-900">Qualified Professionals.</strong> Our team is a collective of dedicated professionals, each with specialized training and a deep understanding of therapeutic techniques.
                                    They are not just practitioners; they are passionate healers who have committed their lives to the art and science of physiotherapy.
                                    Through continuous learning and practical experiences, they have honed their skills to provide therapeutic interventions that make a genuine difference.
                                    Our approach is grounded in evidence-based practices, ensuring that every treatment we offer is both effective and tailored to your unique needs.
                                    With us, you're not just getting a service; you're gaining partners who genuinely care about your holistic well-being.
                                </span>
                            </li>
                            <li className="flex gap-x-3">
                                <TrophyIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                <span>
                                    <strong className="font-semibold text-gray-900">Cutting-Edge Techniques.</strong> In the ever-evolving world of physiotherapy, staying abreast of the latest techniques and research is crucial.
                                    We pride ourselves on our commitment to continuous learning and innovation.
                                    Our professionals regularly participate in advanced training sessions, workshops, and international conferences.
                                    This dedication ensures that we are always equipped with the most recent and effective treatments in the field.
                                    When you choose our services, you're not just getting a standard treatment; you're receiving a cutting-edge therapeutic experience, meticulously crafted to offer you the highest standards of care.
                                    Trust in us to provide you with nothing less than the best.
                                </span>
                            </li>
                            <li className="flex gap-x-3">
                                <BookOpenIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                <span>
                                    <strong className="font-semibold text-gray-900">Education & Empowerment.</strong> Empowerment through knowledge forms the cornerstone of our treatment approach.
                                    We recognize that the journey to healing is not a one-sided effort, but rather a harmonious collaboration between the therapist and the patient.
                                    With this philosophy at heart, we strive to demystify medical jargon and break down complex therapeutic processes into understandable terms.
                                    By offering a clear understanding of their conditions and the science behind our methods, we equip our patients with the tools they need to be active participants in their recovery.
                                    When patients are informed, they feel more in control, more hopeful, and more motivated—ultimately leading to better outcomes. Your healing is our shared mission, and together, we'll chart the path forward.
                                </span>
                            </li>
                        </ul>
                        <p className="mt-8">
                            Physiotherapy is not just about alleviating pain or rehabilitating injuries; it's a holistic journey towards total wellness. At its core, physiotherapy seeks to harmonize the body and mind, paving the way for a revitalized spirit and zest for life. It extends beyond the clinical setting, influencing every facet of an individual's daily life. From the way one walks to the manner in which they engage in their favorite activities, the benefits of physiotherapy are omnipresent.
                            <br /><br />
                            Regaining physical strength is just the starting point. As patients progress in their sessions, they often discover a renewed sense of confidence and self-assurance. This newfound vigor can have a ripple effect, positively influencing their mental and emotional wellbeing. Over time, individuals find themselves more equipped to tackle challenges, more enthused to pursue passions, and more empowered to lead an active, fulfilling life. In essence, physiotherapy does not just heal—it transforms.
                        </p>
                        <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Why Choose Us?</h2>
                        <p className="mt-6">
                            Embarking on a journey with us means choosing more than just treatment; it's opting for a legacy grounded in unparalleled excellence, unwavering commitment, and heartfelt compassion. Every member of our team is dedicated to understanding your unique needs and aspirations, ensuring that each therapy session is not only effective but also deeply personal and empowering.
                            <br /><br />
                            By choosing us, you're not just investing in recovery; you're taking a decisive step towards a life rich in health, mobility, and boundless possibilities. Let's join hands and stride confidently towards a brighter, pain-free tomorrow.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
