import { StarIcon } from '@heroicons/react/20/solid'

export default function Testimonial() {
    return (
        <section className="bg-white px-6 py-24 sm:py-32 lg:px-8">
            <figure className="mx-auto max-w-2xl">
                <p className="sr-only">5 out of 5 stars</p>
                <div className="flex gap-x-1 text-indigo-600">
                    <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
                </div>
                <blockquote className="mt-10 text-xl font-semibold leading-8 tracking-tight text-gray-900 sm:text-2xl sm:leading-9">
                    <p>
                        “In the demanding world of remote work, long hours in front of the computer became my norm.
                        The physical strain started to take its toll, and I often found myself feeling fatigued and uncomfortable.
                        That's when I turned to Anita for guidance.
                        With her profound understanding of the human body and its needs, she meticulously crafted routines tailored just for me.
                        Her instructions were not only effective but seamlessly integrated into my busy schedule.
                        The relief I felt was immediate and lasting. Today, I'm not just more comfortable in my workspace, but I feel revitalized and more energetic.
                        I'm profoundly grateful to Anita for her expertise, dedication, and the genuine care she pours into every session.
                        Here's to better posture, less strain, and a healthier work-life, all thanks to her!”
                    </p>
                </blockquote>
                <figcaption className="mt-10 flex items-center gap-x-6">
                    <img
                        className="h-12 w-12 rounded-full bg-gray-50"
                        src="https://drive.google.com/uc?export=view&id=1cwqBpsvtqZXbE6FlhozZfGhHTqCrSKZF&auto=format&fit=crop&w=1587&q=80"
                        alt=""
                    />
                    <div className="text-sm leading-6">
                        <div className="font-semibold text-gray-900">Joshua Udotai</div>
                        <div className="mt-0.5 text-gray-600">CTO of Dinesurf</div>
                    </div>
                </figcaption>
            </figure>
        </section>
    )
}
