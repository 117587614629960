import Badge from "./Badge";

const features = [
    {
        name: 'Pain Management.',
        description:
            'Harnessing evidence-based techniques to alleviate chronic and acute pain, ensuring you can reclaim your quality of life without persistent discomfort.',
    },
    {
        name: 'Post-Surgical Rehabilitation.',
        description:
            'Tailored programs to accelerate recovery post-surgery, restoring strength, flexibility, and function to the affected areas.',
    },
    {
        name: 'Sports Injury Treatment.',
        description:
            'Whether it’s overuse, strain, or acute trauma, we offer targeted treatments to get athletes back to their optimal performance levels safely.',
    },
    {
        name: 'Manual Therapy.',
        description:
            'Hands-on techniques aimed at reducing pain, improving mobility, and facilitating natural healing by targeting specific areas of the body.',
    },
    {
        name: 'Geriatric Physiotherapy.',
        description:
            'Specialized care designed to address the unique challenges faced by older adults, such as arthritis, osteoporosis, and balance disorders.',
    },
    {
        name: 'Pediatric Physiotherapy.',
        description:
            'Catering to the youngest patients, we offer therapies to address developmental milestones, neuromuscular disorders, and orthopedic conditions in children.',
    },
    {
        name: 'Home-based Exercises.',
        description:
            'Empower your recovery journey even at home. With personalized exercises and guidelines, ensure you’re progressing safely and effectively outside the clinic.',
    }
];


export default function FS1() {
    return (
        <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Comprehensive Physiotherapy Care</h2>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                        Offering a holistic approach to physical well-being, our range of services is designed to cater to your unique needs, ensuring optimal recovery and wellness.
                    </p>
                </div>
                <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                    {features.map((feature) => (
                        <div key={feature.name}><Badge title='Book Now' href='/' />
                            <dt className="font-semibold text-gray-900">{feature.name}</dt>
                            <dd className="mt-1 text-gray-600">{feature.description}</dd>
                        </div>
                    ))}
                </dl>
            </div>
        </div>
    )
}
