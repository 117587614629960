import CS3 from "../Components/ContentSection3";
import Footer from "../Components/Footer";
import Navbar from "../Components/NavBar";
import Team from "../Components/Team";

export default function About() {
    return (
        <>
            <Navbar />
            <CS3 />
            <Team />
            {/* <Header title="Services" subtitle="We offer a wide range of services to help you get back on your feet." /> */}
            <Footer />
        </>
    )
}