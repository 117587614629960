export default function CS3() {
    return (
        <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                    <p className="text-base font-semibold leading-7 text-indigo-600">Holistic Healing</p>
                    <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">About Anita.NG</h1>
                    <div className="mt-10 grid max-w-xl grid-cols-1 gap-8 text-base leading-7 text-gray-700 lg:max-w-none lg:grid-cols-2">
                        <div>
                            <p>
                                Welcome to Anita.ng – a manifestation of passion, dedication, and a grand vision. Originally conceived as a symbol of deep affection and commitment, this brand has evolved to embody much more. While it's deeply rooted in the intimate gesture of gifting to a loved one, Anita.ng has grown in its purpose, blossoming into a platform that aspires to resonate with countless hearts and souls.
                            </p>
                            <p className="mt-8">
                                Every facet of Anita.ng reflects our unwavering commitment to quality and our insatiable passion for what we do. With each endeavor, we encapsulate the essence of genuine affection, ensuring that everyone, regardless of their background or story, feels seen, heard, and cherished. Our ethos transcends boundaries, inviting individuals from all walks of life to be part of this enriching narrative.
                            </p>
                            <p className="mt-8">
                                As a brand, we constantly strive for excellence. Through meticulous attention to detail, a deep understanding of our audience's needs, and an unyielding drive to innovate, Anita.ng promises not just products or services, but experiences. Experiences that linger, resonate, and become cherished memories.
                            </p>
                        </div>
                        <div>
                            <p>
                                The essence of Anita.ng is multifaceted. It's deeply personal, yet universally relatable. It's about treasuring individual narratives while weaving them into a collective tapestry of shared experiences. As we embark on this journey, our mission remains clear: to create, nurture, and elevate connections, one heart at a time.
                            </p>
                            <p className="mt-8">
                                Our approach is holistic. We understand that every interaction, be it big or small, holds immense potential. That's why, with Anita.ng, each touchpoint becomes a celebration of love, commitment, dreams, and aspirations. Our vision stretches beyond the horizon; we seek to create a legacy—a legacy where each individual, irrespective of where they come from, finds their place, voice, and purpose.
                            </p>
                            <p className="mt-8">
                                So, as you navigate the world of Anita.ng, we invite you to become part of our story—a story of love, ambition, and the pursuit of greatness. Embrace this narrative, join hands with us, and let's co-create a world where everyone feels at home, valued, and infinitely cherished.
                            </p>
                        </div>
                    </div>
                    <div className="mt-10 flex">
                        <a
                            href="#"
                            className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Get started
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
