import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './Pages/HomePage';
import Physiotherapy from './Pages/PhysiotherapyPage';
import Health from './Pages/YourHealth';
import Services from './Pages/Services';
import About from './Pages/About';
import Birthday from './Pages/Birthday';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/physiotherapy" element={<Physiotherapy />} />
        <Route path="/health" element={<Health />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about" element={<About />} />
        <Route path="/birthday" element={<Birthday />} />
      </Routes>
    </Router>
  );
}

export default App;
