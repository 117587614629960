import React, { useState } from 'react';
import axios from 'axios';
import ToastNotification from './Toast';
import { PropagateLoader } from 'react-spinners';

export default function BirthdayDropDown() {
    const [location, setLocation] = useState('Canada');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [bestieCode, setBestieCode] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [toastType, setToastType] = useState(null);  // Could be 'error' or 'success'
    const [toastMessage, setToastMessage] = useState('');  // The message to display in the toast
    const [loading, setLoading] = useState(false);

    const handleLocationChange = (e) => setLocation(e.target.value);
    const handlePhoneNumberChange = (e) => setPhoneNumber(e.target.value);
    const handleBestieCodeChange = (e) => setBestieCode(e.target.value);

    const handleSubmit = async () => {
        setLoading(true);  // Start the loader

        try {
            const payload = { location, phoneNumber, bestieCode };
            const response = await axios.post('https://api.dinesurf.tech/anita/birthday_airtime/', payload);
            console.log(payload, response.data)
            if (response.data.status === 'error') {
                setToastType('error');
                setToastMessage(response.data.message);
            } else if (response.data.status === 'success') {
                setToastType('success');
                setToastMessage(response.data.message);
            }

            setShowToast(true);

            // Hide the toast after 6 seconds
            setTimeout(() => setShowToast(false), 6000);
        } catch (error) {
            console.log('Error sending data', error);
            setToastType('error');
            setToastMessage('An error occurred. Please try again.');
            setShowToast(true);
            setTimeout(() => setShowToast(false), 6000);
        } finally {
            setLoading(false);  // Stop the loader
        }
    };
    return (
        <div>
            <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
                Network
            </label>
            <select
                id="location"
                name="location"
                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={location}
                onChange={handleLocationChange}
            >
                <option></option>
                <option>MTN</option>
                <option>GLO</option>
                <option>AIRTEL</option>
                <option>9MOBILE</option>
            </select>
            <label htmlFor="phoneNumber" className="block text-sm font-medium leading-6 text-gray-900 mt-4">
                Phone Number
            </label>
            <input
                id="phoneNumber"
                name="phoneNumber"
                type="text"
                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
            />
            <label htmlFor="code" className="block text-sm font-medium leading-6 text-gray-900 mt-4">
                Bestie Secret Code
            </label>
            <input
                id="code"
                name="code"
                type="text"
                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={bestieCode}
                onChange={handleBestieCodeChange}
            />
            <button
                onClick={handleSubmit}
                className="mt-4 bg-indigo-500 text-white py-2 px-4 rounded-md"
            >
                Submit
            </button>

            {showToast && (
                <ToastNotification
                    icon={toastType === 'error' ? 'FaSkull' : 'FaSmileWink'}
                    title={toastMessage}
                />
            )}
            <br />
            <PropagateLoader color="#0891b2" loading={loading} />
        </div>
    );
}
