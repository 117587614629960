import ContactForm from "../Components/ContactForm";
import ContactGrid from "../Components/ContactGrid";
import FAQ from "../Components/FAQ";
import Features from "../Components/Features";
import Footer from "../Components/Footer";
import HeroSection from "../Components/HeroSection";
import Navbar from "../Components/NavBar";
import Testimonial from "../Components/Testimonial";

export default function HomePage() {
    return (
        <>
            <Navbar />
            <HeroSection />
            <Features />
            <Testimonial />
            <ContactGrid />
            <ContactForm />
            {/* <Banner /> */}
            {/* <img src="https://drive.google.com/uc?export=view&id=19uELT0jC_FrLcIoTiEX6BkYj5Nz68JXB"></img> */}
            <FAQ />
            <Footer />
        </>
    )
}
