import CS1 from "../Components/ContentSection1";
import Footer from "../Components/Footer";
import Navbar from "../Components/NavBar";
import { Helmet } from 'react-helmet';

export default function Physiotherapy() {
    return (
        <>
            <Helmet>
                <title>Home - Anita's Physiotherapy</title>
                <meta name="description" content="Welcome to Anita's Physiotherapy services. Discover treatments, book sessions, and read testimonials." />
                <meta name="keywords" content="Physiotherapy Nigeria, Nigerian Physiotherapists, Rehabilitation services Nigeria, Physical therapy Nigeria, Pain management Nigeria, Sports injury rehab Nigeria, Physiotherapy in Lagos, Post-surgery rehabilitation Nigeria" />
            </Helmet>
            <Navbar />
            <CS1 />
            <Footer />
        </>
    )
}
