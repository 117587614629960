import { Fragment, useState } from 'react';
import { ForwardIcon } from '@heroicons/react/20/solid';
import axios from 'axios';
import Alert from './Alert';
import { BarLoader } from 'react-spinners';

export default function TB1() {
    const [showAlert, setShowAlert] = useState(false);
    const [loading, setLoading] = useState(false);  // New state for loading

    const handleSubmit = async (event) => {
        event.preventDefault();

        setLoading(true); // Set loading to true when sending the request

        const title = event.target.title.value;
        const description = event.target.description.value;

        try {
            const response = await axios.post('https://api.dinesurf.tech/anita/get_wishes/', { title, description });

            if (response.data.status === 'success') {
                setShowAlert(true);
                setTimeout(() => setShowAlert(false), 3000);
                window.location.reload();
            } else {
                // Handle the error scenario
            }

        } catch (error) {
            console.error('There was an error posting the data', error);
        } finally {
            setLoading(false); // Set loading to false once you get a response or an error
        }
    }

    return (
        <Fragment>
            {showAlert && <Alert title="Success!" subtitle="Your wish has been posted." />}

            <form action="#" className="relative" onSubmit={handleSubmit}>
                <div className="overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
                    <label htmlFor="title" className="sr-only">
                        From
                    </label>
                    <input
                        type="text"
                        name="title"
                        id="title"
                        className="block w-full border-0 pt-2.5 text-lg font-medium placeholder:text-gray-400 focus:ring-0"
                        placeholder="From"
                    />
                    <label htmlFor="description" className="sr-only">
                        Birthday Wish
                    </label>
                    <textarea
                        rows={2}
                        name="description"
                        id="description"
                        className="block w-full resize-none border-0 py-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="Write a birthday wish..."
                        defaultValue={''}
                    />

                    {/* Spacer element to match the height of the toolbar */}
                    <div aria-hidden="true">
                        <div className="py-2">
                            <div className="h-9" />
                        </div>
                        <div className="h-px" />
                        <div className="py-2">
                            <div className="py-px">
                                <div className="h-9" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="absolute inset-x-px bottom-0">
                    <div className="flex items-center justify-between space-x-3 border-t border-gray-200 px-2 py-2 sm:px-3">
                        <div className="flex">
                            <button
                                type="button"
                                className="group -my-2 -ml-2 inline-flex items-center rounded-full px-3 py-2 text-left text-gray-400"
                            >
                                <ForwardIcon className="-ml-1 mr-2 h-5 w-5 group-hover:text-gray-500" aria-hidden="true" />
                                <span className="text-sm italic text-gray-500 group-hover:text-gray-600">Hit Create to Post</span>
                            </button>
                        </div>
                        <div className="flex-shrink-0">
                            <button
                                type="submit"
                                className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Create
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            <BarLoader color="#10B981" loading={loading} css="" size={150} />
        </Fragment>
    );
}