import { Disclosure } from '@headlessui/react';
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline';

const faqs = [
    {
        question: "What conditions can physiotherapy treat?",
        answer: "Physiotherapy can help treat a wide range of conditions, from chronic pain, arthritis, and sports injuries to post-surgical recovery, neurological disorders, and more. The goal is to restore, maintain, and optimize physical function and overall well-being."
    },
    {
        question: "How long is each physiotherapy session?",
        answer: "Typically, an initial assessment lasts about 60 minutes. Subsequent treatment sessions can vary in length but usually last between 30 to 60 minutes. The exact duration will depend on your individual needs and treatment plan."
    },
    {
        question: "Will I need a doctor's referral for physiotherapy?",
        answer: "You do not always need a doctor's referral to start physiotherapy. However, some insurance providers may require one for reimbursement purposes. It's advisable to check with your insurance before starting therapy."
    },
    {
        question: "Is physiotherapy painful?",
        answer: "While physiotherapy aims to alleviate pain, some treatments or exercises might cause temporary discomfort. This is because we're targeting specific areas to promote healing. Your physiotherapist will work with you to ensure treatments are as comfortable as possible."
    },
    {
        question: "How often will I need treatment?",
        answer: "The frequency of treatment varies depending on the individual and the condition being treated. After your initial assessment, your physiotherapist will recommend a treatment plan tailored to your needs."
    },
    {
        question: "Do you offer home visits?",
        answer: "Yes, we offer on-site therapy services for those who prefer treatments in their own space. Please contact us for more details on scheduling and availability."
    },
    {
        question: "Are your services covered by insurance?",
        answer: "Many insurance plans cover physiotherapy services. It's recommended to check with your insurance provider about coverage specifics. We provide receipts suitable for insurance claims."
    }
    // Add more questions as needed...
];

export default function FAQ() {
    return (
        <div className="mx-auto mb-24 max-w-7xl px-6 sm:mb-56 lg:px-8">
            <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
                <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                    {faqs.map((faq) => (
                        <Disclosure as="div" key={faq.question} className="pt-6">
                            {({ open }) => (
                                <>
                                    <dt>
                                        <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                                            <span className="text-base font-semibold leading-7">{faq.question}</span>
                                            <span className="ml-6 flex h-7 items-center">
                                                {open ? (
                                                    <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                ) : (
                                                    <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                )}
                                            </span>
                                        </Disclosure.Button>
                                    </dt>
                                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                        <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                    ))}
                </dl>
            </div>
        </div>
    );
}
